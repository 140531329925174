


















































































































































































































































































































































import Vue from "vue";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_TIME } from "@constant/date.constant";
import { quotationService } from "@service/quotation.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseListQuotation, ResponseQuotation } from "@interface/quotation.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import moment from "moment";
import { ResponseListContactData } from "@interface/contact.interface";
import { contactServices } from "@service/contact.service";
import { ResponseListBranchWarehouse } from "@interface/warehouse.interface";
import { warehouseBranchService } from "@service/warehouse-branch.service";
import { ResponseListMasterType } from "@interface/master.interface";
import { masterTypeService } from "@service/master-type.service";
import printJS from "print-js";
import { QUOTATION_STATUS } from "@/models/enums/quotation.enum";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Quotation",
  components: {
    CSelectCustomer: () => import(/*webpackPrefetch: true */"@/components/shared/select-customer/CSelectCustomer.vue")
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      DEFAULT_DATE_FORMAT_TIME,
      formModel: {
        customerName: undefined as string | undefined,
        customerPoNumber: "",
        rfqNumber: "",
        branch: undefined as string | undefined,
        salesName: "",
        orderDate: null as string | null,
        etaDate: null as string | null,
        status: undefined as string | undefined,
        rfqCreatedDate: null as string[] | null
      },
      formProps: {
        customerName: {
          label: "lbl_customer_name",
          placeholder: "lbl_choose"
        },
        customerPoNumber: {
          label: "lbl_customer_po_number",
          placeholder: "lbl_type_here"
        },
        rfqNumber: {
          label: "lbl_rfq_number",
          placeholder: "lbl_type_here"
        },
        branch: {
          label: "lbl_branch",
          placeholder: "lbl_choose"
        },
        salesName: {
          label: "lbl_sales_person_name",
          placeholder: "lbl_type_here"
        },
        orderDate: {
          label: "lbl_order_date",
          placeholder: "lbl_choose"
        },
        etaDate: {
          label: "lbl_eta_date",
          placeholder: "lbl_choose"
        },
        status: {
          label: "lbl_status",
          placeholder: "lbl_choose"
        },
        rfqCreatedDate: {
          label: "lbl_rfq_created_date",
          placeholder: "lbl_choose"
        },
      },
      loading: {
        customerName: false,
        branch: false,
        status: false,
        table: false,
        download: false
      },
      dtListQuotation: {} as ResponseListQuotation,
      dtParams: {
        limit: DEFAULT_PAGE_SIZE,
        sorts: "orderDate:desc",
        page: 0
      } as RequestQueryParamsModel,
      dtOpt: {
        customerName: [] as {key: string, value: string}[],
        branch: [] as {key: string, value: string}[],
        status: [] as {key: string, value: string}[],
      }
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "quotation" && x.privilege.create);
    },
    hasPrivilegeUpdate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "quotation" && x.privilege.update);
    },
    formCol() {
      return {
        labelCol: {
          sm: { span: 24 },
          md: { span: 12 },
          lg: { span: 12 },
        },
        wrapperCol: {
          sm: { span: 24 },
          md: { span: 12 },
          lg: { span: 12 },
        },
      };
    }
  },
  created() {
    this.findQuotations(this.dtParams);
    this.getListStatus();
  },
  methods: {
    moment,
    getListQuotation(params: RequestQueryParamsModel): Promise<ResponseListQuotation> {
      return quotationService.getListQuotation(params);
    },
    downloadListQuotation(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
      return quotationService.downloadReportQuotation(params);
    },
    getListCustomer(params: RequestQueryParamsModel): Promise<ResponseListContactData> {
      return contactServices.listContactData(params);
    },
    getListBranch(params: RequestQueryParamsModel): Promise<ResponseListBranchWarehouse> {
      return warehouseBranchService.listWarehouseBranch(params);
    },
    getListMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    handleCreateNew(): void {
      this.$router.push({name: "sales.transactionsales.quotation.create"});
    },
    gotoView(record: ResponseQuotation): void {
      this.$router.push({name: "sales.transactionsales.quotation.edit", params: { id: record.id }});
    },
    showErrorMessage(message: string): void {
      this.$message.error({
        content: this.$t(message).toString()
      });
    },
    isRecordQuotationSubmitted(record: ResponseQuotation): boolean {
      return record.status === QUOTATION_STATUS.QUOTATION_SUBMITTED;
    },
    async findQuotations(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading.table = true;
        const res = await this.getListQuotation(params);
        res.data.forEach((x, i) => x["key"] = i);
        this.dtListQuotation = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.table = false;
      }
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.dtParams.limit = response.size;
      this.dtParams.page = 0;
      this.findQuotations(this.dtParams);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.dtParams.page = response.page - 1;
      this.findQuotations(this.dtParams);
    },
    async getListStatus(): Promise<void> {
      try {
        this.loading.status = true;
        const res = await this.getListMasterType({name: "RFQ_STATUS"});
        this.dtOpt.status = res.map(x => { return { key: x.value, value: x.value };});
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.status = false;
      }
    },
    resetForm(): void {
      this.formModel = {
        customerName: undefined as string | undefined,
        customerPoNumber: "",
        rfqNumber: "",
        branch: undefined as string | undefined,
        salesName: "",
        orderDate: null,
        etaDate: null,
        status: undefined as string | undefined,
        rfqCreatedDate: null
      };
    },
    handleClear(): void {
      this.resetForm();
      if (this.dtParams.search) {
        this.dtParams.search = "";
      }
      this.dtParams.page = 0;
    },
    buildDateParam(rangeDate: string[] = []): string[] {
      const start = this.moment(rangeDate[0]).set({ hour: 0, minute: 0, second: 0}).utcOffset("+07").format();
      const end = this.moment(rangeDate[1]).set({ hour: 23, minute: 59, second: 59}).utcOffset("+07").format();
      return [start, end];
    },
    handleFind(): void {
      const searchBy: string[] = [];
      const { formModel } = this;
      if (formModel.customerName) {
        searchBy.push(`customer.secureId~${formModel.customerName}`);
      }
      if (formModel.customerPoNumber) {
        searchBy.push(`customerPo~*${formModel.customerPoNumber}*`);
      }
      if (formModel.rfqNumber) {
        searchBy.push(`rfqNumber~*${formModel.rfqNumber}*`);
      }
      if (formModel.branch) {
        searchBy.push(`branch.secureId~${formModel.branch}`);
      }
      if (formModel.salesName) {
        searchBy.push(`salesName~*${formModel.salesName}*`);
      }
      if (formModel.orderDate) {
        const [start, end] = this.buildDateParam([formModel.orderDate, formModel.orderDate]);
        searchBy.push(`orderDate>=${start}_AND_orderDate<=${end}`);
      }
      if (formModel.etaDate) {
        const [start, end] = this.buildDateParam([formModel.etaDate, formModel.etaDate]);
        searchBy.push(`etaDate>=${start}_AND_etaDate<=${end}`);
      }
      if (formModel.status) {
        searchBy.push(`status~${formModel.status.replace(/\s/gi, "_").toUpperCase()}`);
      }
      if (formModel.rfqCreatedDate) {
        const [start, end] = this.buildDateParam(formModel.rfqCreatedDate);
        searchBy.push(`rfqDate>=${start}_AND_rfqDate<=${end}`);
      }
      this.dtParams.search = searchBy.join("_AND_");
      this.findQuotations(this.dtParams);
    },
    async handleDownload(): Promise<void> {
      try {
        this.loading.download = true;
        const file = await this.downloadListQuotation(this.dtParams);
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report_quotation.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.download = false;
      }
    },
    handlePrint(): void {
      const dt = this.dtListQuotation.data;
      dt.forEach(x => {
        x.orderDate = this.moment(x.orderDate).format(DEFAULT_DATE_FORMAT);
        x.etaDate = this.moment(x.etaDate).format(DEFAULT_DATE_FORMAT_TIME);
      });
      const properties = [
        {
          field: "customerName",
          displayName: this.$t("lbl_customer_name")
        },
        {
          field: "customerPo",
          displayName: this.$t("lbl_customer_po_number")
        },
        {
          field: "rfqNumber",
          displayName: this.$t("lbl_rfq_number")
        },
        {
          field: "orderDate",
          displayName: this.$t("lbl_order_date")
        },
        {
          field: "etaDate",
          displayName: this.$t("lbl_eta_date")
        },
        {
          field: "salesName",
          displayName: this.$t("lbl_sales_person_name")
        },
        {
          field: "status",
          displayName: this.$t("lbl_status")
        },
      ];
      printJS({
        printable: dt,
        properties,
        type: "json",
        gridHeaderStyle: "border: 1px solid #000",
        gridStyle: "text-align: center;border: 1px solid #000",
        onError: (error) => {
          this.$notification.error({
            message: this.$t("lbl_error_title").toString(),
            description: error.message
          });
        }
      });
    }
  }
});
