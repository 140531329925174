var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_list_quotation") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                model: _vm.formModel,
                "label-align": "left",
                "label-col": _vm.formCol.labelCol,
                "wrapper-col": _vm.formCol.wrapperCol
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.customerName.label)
                          }
                        },
                        [
                          _c("CSelectCustomer", {
                            model: {
                              value: _vm.formModel.customerName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "customerName", $$v)
                              },
                              expression: "formModel.customerName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.customerPoNumber.label)
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "allow-clear": "",
                              placeholder: _vm.$t(
                                _vm.formProps.customerPoNumber.placeholder
                              )
                            },
                            model: {
                              value: _vm.formModel.customerPoNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "customerPoNumber", $$v)
                              },
                              expression: "formModel.customerPoNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.rfqNumber.label)
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "allow-clear": "",
                              placeholder: _vm.$t(
                                _vm.formProps.rfqNumber.placeholder
                              )
                            },
                            model: {
                              value: _vm.formModel.rfqNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "rfqNumber", $$v)
                              },
                              expression: "formModel.rfqNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t(_vm.formProps.branch.label) }
                        },
                        [
                          _c("CSelectBranch", {
                            model: {
                              value: _vm.formModel.branch,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "branch", $$v)
                              },
                              expression: "formModel.branch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.salesName.label)
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "allow-clear": "",
                              placeholder: _vm.$t(_vm.formProps.salesName.label)
                            },
                            model: {
                              value: _vm.formModel.salesName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "salesName", $$v)
                              },
                              expression: "formModel.salesName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.orderDate.label)
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formProps.orderDate.placeholder
                              ),
                              format: _vm.DEFAULT_DATE_FORMAT
                            },
                            model: {
                              value: _vm.formModel.orderDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "orderDate", $$v)
                              },
                              expression: "formModel.orderDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t(_vm.formProps.etaDate.label) }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formProps.etaDate.placeholder
                              ),
                              format: _vm.DEFAULT_DATE_FORMAT
                            },
                            model: {
                              value: _vm.formModel.etaDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "etaDate", $$v)
                              },
                              expression: "formModel.etaDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t(_vm.formProps.status.label) }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                placeholder: _vm.$t(
                                  _vm.formProps.status.placeholder
                                ),
                                loading: _vm.loading.status
                              },
                              model: {
                                value: _vm.formModel.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "status", $$v)
                                },
                                expression: "formModel.status"
                              }
                            },
                            _vm._l(_vm.dtOpt.status, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(item.key) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(item.key) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.rfqCreatedDate.label)
                          }
                        },
                        [
                          _c("a-range-picker", {
                            attrs: {
                              placeholder: [
                                _vm.$t(
                                  _vm.formProps.rfqCreatedDate.placeholder
                                ),
                                _vm.$t(_vm.formProps.rfqCreatedDate.placeholder)
                              ]
                            },
                            model: {
                              value: _vm.formModel.rfqCreatedDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "rfqCreatedDate", $$v)
                              },
                              expression: "formModel.rfqCreatedDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            {
              attrs: {
                gutter: [16, 16],
                type: "flex",
                justify: "space-between",
                align: "middle"
              }
            },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleClear } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.handleFind }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _vm.hasPrivilegeCreate
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "plus" },
                          on: { click: _vm.handleCreateNew }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-table",
                    {
                      attrs: {
                        "data-source": _vm.dtListQuotation.data,
                        pagination: false,
                        loading: _vm.loading.table,
                        scroll: { x: "calc(900px + 50%)", y: 520 }
                      }
                    },
                    [
                      _c(
                        "a-table-column",
                        {
                          key: "customerName",
                          attrs: { "data-index": "customerName" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_customer_name")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "customerPo",
                          attrs: { "data-index": "customerPo" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_customer_po_number")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "rfqNumber",
                          attrs: { "data-index": "rfqNumber" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_rfq_number")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "orderDate",
                          attrs: { "data-index": "orderDate" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(orderDate) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("date")(orderDate)) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_order_date")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "etaDate",
                          attrs: { "data-index": "etaDate" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(etaDate) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(_vm._f("date")(etaDate)) + " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_eta_date")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "rfqDate",
                          attrs: { "data-index": "rfqDate" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(rfqDate) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("dateTime")(rfqDate)) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_rfq_created_date")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "branchName",
                          attrs: { "data-index": "branchName" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_branch")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "salesName",
                          attrs: { "data-index": "salesName" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_sales_person_name")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "status",
                          attrs: {
                            "data-index": "status",
                            fixed: "right",
                            width: 120,
                            align: "center"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_status")))]
                          )
                        ]
                      ),
                      _vm.hasPrivilegeCreate || _vm.hasPrivilegeUpdate
                        ? _c(
                            "a-table-column",
                            {
                              key: "action",
                              attrs: {
                                fixed: "right",
                                width: 100,
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(text, record) {
                                      return [
                                        _c(
                                          "a-dropdown",
                                          {
                                            attrs: {
                                              trigger: ["click"],
                                              placement: "bottomCenter"
                                            }
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "more" },
                                              on: {
                                                click: function(e) {
                                                  return e.preventDefault()
                                                }
                                              }
                                            }),
                                            _c(
                                              "a-menu",
                                              {
                                                attrs: { slot: "overlay" },
                                                slot: "overlay"
                                              },
                                              [
                                                _c(
                                                  "a-menu-item",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.gotoView(
                                                          record
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.isRecordQuotationSubmitted(
                                                            record
                                                          )
                                                            ? _vm.$t("lbl_edit")
                                                            : _vm.$t("lbl_view")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                4213413390
                              )
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(_vm.$t("lbl_action")))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex", align: "middle" } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 18 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.dtListQuotation.totalElements,
                      "page-size-set": _vm.dtParams.limit,
                      "id-pagination": "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 6, align: "end" } },
                [
                  _c("a-tag", { attrs: { color: "grey" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_total_found")) +
                        " : " +
                        _vm._s(_vm.dtListQuotation.totalElements) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex", justify: "end" } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { icon: "printer", type: "primary" },
                      on: { click: _vm.handlePrint }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        icon: "download",
                        type: "primary",
                        loading: _vm.loading.download
                      },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }