import { Api } from "@/models/class/api.class";
import { RequestQuotationCreate, RequestQuotationUpdate, ResponseCreateQuotation, ResponseListQuotation, ResponseQuotationDetail } from "@interface/quotation.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class QuotationServices extends HttpClient {
  constructor() {
    super();
  }

  getListQuotation(params: RequestQueryParamsModel): Promise<ResponseListQuotation> {
    return this.get<ResponseListQuotation>(Api.Quotation, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  createQuotation(payload: RequestQuotationCreate): Promise<ResponseCreateQuotation> {
    return this.post<ResponseCreateQuotation, RequestQuotationCreate>(Api.Quotation, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getDetailQuotation(id: string): Promise<ResponseQuotationDetail> {
    return this.get<ResponseQuotationDetail>(`${Api.Quotation}/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  updateQuotation(id: string, payload: RequestQuotationUpdate): Promise<ResponseQuotationDetail> {
    return this.put<ResponseQuotationDetail, RequestQuotationUpdate>(`${Api.Quotation}/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
  
  getLatestQuotation(id: string): Promise<ResponseQuotationDetail> {
    return this.get<ResponseQuotationDetail>(`${Api.QuotationOrderCustomer}/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
  
  downloadReportQuotation(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.Quotation}/report/download`, { params, responseType: "arraybuffer"})
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
}

export const quotationService = new QuotationServices();
